import React from "react"
import Terms from "../components/terms"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./privacy_policy.css"

const TermsModels = () => {
  return (
    <Layout page="terms">
      <SEO title="Terms of Use - Models" description="Our terms of use for models on our network." />

      <div className="flex-grid">
        <div className="col" />
        <div style={{ flex: "0 1 994px", marginTop: "50px" }}>
          <Terms active="models">
            <div className="privacy-policy-title" style={{marginTop: '-20px'}}>Terms of Use - Models</div>
            <div className="privacy-policy-text">
              <p>NEON COAT, LLC (&ldquo;<strong>NEON COAT</strong>&rdquo;&nbsp;or&nbsp;&ldquo;<strong>we</strong>&rdquo;&nbsp;or&nbsp;&ldquo;<strong>us</strong>&rdquo;&nbsp;or&nbsp;&ldquo;<strong>our</strong>&rdquo;) provides a platform for directly connecting businesses and signed models (the&nbsp;&ldquo;<strong>Services</strong>&rdquo;).</p>
              <p>These Terms of Service (&ldquo;<strong>Terms</strong>&rdquo;) apply to your access and use of the Neon Coat mobile application (the&nbsp;&ldquo;<strong>App</strong>&rdquo;), our website located at <a href="https://www.neoncoat.com">https://www.neoncoat.com</a> (the&nbsp;&ldquo;<strong>Site</strong>&rdquo;), our proprietary platform that powers the Services (the&nbsp;&ldquo;<strong>Platform</strong>&rdquo;) and other online products and services of Neon Coat (collectively, with the App and the Site, the&nbsp;&ldquo;<strong>Neon Coat Services</strong>&rdquo;). These Terms will govern any upgrades to the Neon Coat Services, unless such upgrade is accompanied by a separate license, in which case the terms of that license will govern.</p>
              <p><strong>The Neon Coat Services</strong><br /> Through the Neon Coat Services, models are informed of businesses or event promoters (each, an &ldquo;<strong>Organizer</strong>&rdquo;) seeking models for a variety of promotional purposes, which may include (without limitation) modeling or other professional gigs, sampling the Organizer&rsquo;s products and services, and attending Organizer venues or events (each, an&nbsp;&ldquo;<strong>Engagement</strong>&rdquo;). When an Organizer requests models for a particular Engagement, it will provide Engagement details (e.g., the number of models requested, date and time, terms and conditions, etc.), any additional criteria it seeks for the models, and any associated incentives or other compensation offered (&ldquo;<strong>Offers</strong>&rdquo;). If you meet the necessary criteria, are interested, and there is available space, you can accept the Offer via the App, which (if applicable) will generate an electronic ticket (&ldquo;<strong>E-Ticket</strong>&rdquo;). If you back out of one or more confirmed Engagements without legitimate and verified excuse, we may suspend your access to and use of the Neon Coat Services, in our sole discretion. It will be your responsibility to comply with all stated terms and conditions of each Offer, which may require contacting an Organizer in advance to make an appointment. We reserve the right to limit users&rsquo; access to certain Offers in our sole discretion. Should you encounter any inappropriate conduct or behavior by an Organizer, please contact us immediately at <a href="mailto:info@neoncoat.com">info@neoncoat.com</a>.</p>
              <p><strong>Conditions of Use</strong><br /> You may not use the Neon Coat Services unless (i) you are at least 18 years old; and (ii) you agree to these Terms, which may be modified at any time. If you disagree with any such modifications, your only remedy is to immediately discontinue use of the Neon Coat Services.</p>
              <p><strong>Acceptance</strong><br /> BY DOWNLOADING THE APP OR OTHERWISE ACCESSING OR USING THE NEON COAT SERVICES, YOU AGREE TO BE BOUND BY ALL OF THE TERMS HEREIN, INCLUDING YOUR AGREEMENT TO SUBMIT ANY DISPUTES TO BINDING ARBITRATION. Please read all of the terms before you use the Neon Coat Services. If a term does not make sense to you, please let us know.</p>
              <p><strong>Modifications</strong><br /> We reserve the right to change these Terms from time to time. If we make changes, we will notify you by revising the date at the top of the Terms (and, in some cases, providing you with additional notice such as adding a statement to our homepage or sending you an email notification). All users of Neon Coat Services should review the Terms periodically to ensure they stay informed of any updates. Changes to these Terms will be effective when posted (unless you are notified otherwise), and your continued use of Neon Coat Services after the effective date of any such revisions will signify your acceptance of those changes.</p>
              <p><strong>Privacy Policy</strong><br /> For information about how we collect, use and share information about users of the Neon Coat Services, please see our Privacy Policy at <a href="https://www.neoncoat.com/privacy_policy">https://www.neoncoat.com/privacy_policy</a>.</p>
              <p><strong>Accounts</strong><br /> You must create an account to use the Neon Coat Services, which may require that you link to one or more of your social media accounts (e.g., Instagram). You are responsible for all activity performed through your account, and accept all risk that someone may access your account without your permission. As such, you should keep your log-in credentials secure. By creating an account with Neon Coat, you represent and warrant to us that (i) you are at least 18 years old; (ii) all information that you provide in connection with your account is at all times truthful, accurate, current and complete; (iii) your use of the Neon Coat Services will not violate any contractual obligations you have to any third parties (including, without limitation, modeling or other agencies); and (iv) you will comply with these Terms. Neon Coat reserves the right to deny or terminate any account, at our discretion, for any reason. You promise to update the information you have provided to Neon Coat in the event of any changes to your account information.</p>
              <p><strong>Offers</strong><br /> Neon Coat makes no representations or warranties in connection with Offers, which originate from Organizers. Should an Organizer fail to honor its Offer, however, please report it to Neon Coat immediately.</p>
              <p>Your participation in Engagements will be monitored via various mechanisms, including (if applicable) geolocation information collected by the App from your mobile device, as well as manual attendance verification. When participating in Engagements that require your physical presence at a specific location, you must present your E-Ticket to the Organizer upon arrival so they can ensure you are properly checked in. Please keep your mobile device powered and with you while participating in such Engagements. If you leave an Engagement for any reason prior to its conclusion or fail to conduct yourself appropriately or adhere to an Organizer&rsquo;s reasonable expectations or Engagement-specific guidelines, Offers may be reduced or withheld entirely.</p>
              <p>Neon Coat reserves the right to limit your access to any Offers in its sole discretion.</p>
              <p><strong>Payments<br /> </strong>For certain Engagements, Neon Coat may collect compensation directly from Organizers, and remit your share of any such compensation (in accordance with the terms of such Engagement) to you directly. In connection with such payments, the following terms apply:</p>
              <ul>
                <li>Payments will be made solely to the extent that the applicable Organizer pays Neon Coat. While Neon Coat will use reasonable efforts to secure such payments up front, and to ensure all Organizers meet their payment obligations to Neon Coat, Neon Coat will have no obligation to pay you unless and until Neon Coat receives the applicable payment, in full, from the Organizer.</li>
              </ul>
              <ul>
                <li>All payments will be made using the payment solution Stripe (or such other payment solution as authorized by Neon Coat on a case-by-case basis, in its sole and absolute discretion). Unless otherwise expressly authorized by Neon Coat, in its sole and absolute discretion, <strong>you </strong><strong>must</strong><strong> have a valid account with Stripe to be eligible for payments from Neon Coat</strong>. Failure to do so will result in complete forfeiture of any rights to payment you might otherwise have been entitled to in connection with an Engagement. Your use of the Stripe payment solution will be governed by Stripe&rsquo;s applicable terms of service.</li>
              </ul>
              <ul>
                <li>Neon Coat is not your employer and will accordingly not be withholding any employment, income or other taxes from such payments. You will be solely responsible for any and all such taxes.&nbsp;</li>
              </ul>
              <p><strong>No Agency</strong><br /> Neon Coat does not intend to appoint you or any other Neon Coat model as its employee or legal agent, or to form any kind of legal partnership or joint venture with you. You are not authorized to make any commitments on behalf of Neon Coat, and Neon Coat will not make commitments on your behalf, except as expressly authorized via the Neon Coat Services or as expressly stated in these Terms.</p>
              <p><strong>Right to Use the Neon Coat Services</strong><br /> Provided that you fully comply with these Terms, Neon Coat grants you a limited, nonexclusive, non-transferable and revocable license to access and use the Neon Coat Services for your own personal use. It is the responsibility of all Neon Coat models to ensure that their account is controlled solely by them at all times.&nbsp;</p>
              <p>Except as expressly authorized by these Terms, you may not (a) modify, disclose, alter, translate or create derivative works of the Neon Coat Services, (b) license, sublicense, resell, distribute, lease, rent, lend, transfer, assign or otherwise dispose of the Neon Coat Services, (c) disassemble, decompile or reverse engineer any of the software components of the Neon Coat Services, (d) copy, frame or mirror any part of the Neon Coat Services, (e) interfere with or disrupt the integrity or performance of the Neon Coat Services, or (f) attempt to gain unauthorized access to the Neon Coat Services or its related systems or networks.</p>
              <p><strong>Prohibited Use of the Neon Coat Services</strong><br /> You may not do any of the following in connection with the Neon Coat Services or other Neon Coat users:</p>
              <ul>
                <li>Use the Neon Coat Services in any manner that could interfere with, disrupt, negatively affect or inhibit other users from fully enjoying the Neon Coat Services or that could damage, disable, overburden or impair the functioning of the Neon Coat Services;</li>
                <li>Collect any personal information about other users;</li>
                <li>Intimidate, threaten, stalk, bully or otherwise harass other users;</li>
                <li>Create an account if you are not over 18 years of age;</li>
                <li>Use the Neon Coat Services as a platform for dating or matchmaking services or any other purpose except for the Services as described at the beginning of these Terms;</li>
                <li>Use the Neon Coat Services for or in connection with any illegal or unauthorized purpose (including, without limitation, any use that would violate the FTC&rsquo;s Endorsement Guides) or to engage in, encourage or promote any activity that is unlawful or that violates these Terms;&nbsp;</li>
                <li>Use the Neon Coat Services in any manner that would conflict with your existing contractual obligations to third parties (including, without limitation, contractual obligations to modeling or other agencies); or</li>
                <li>Circumvent or attempt to circumvent any filtering, security measures, rate limits or other features designed to protect the Neon Coat Services, its users, or third parties.</li>
              </ul>
              <p>You are expected to use common sense and to know your own limits pertaining to the consumption of alcohol when participating in any Engagements. YOU ALSO ACKNOWLEDGE AND AGREE THAT YOUR DECISION TO CONSUME ALCOHOL IN CONNECTION WITH AN ENGAGEMENT IS SOLELY A PERSONAL DECISION, AND YOU ASSUME ALL RISKS, AND ACCEPT FULL RESPONSIBILITY FOR ANY AND ALL CONSEQUENCES, RESULTING FROM YOUR CONSUMPTION OF ALCOHOL.</p>
              <p><strong>Release of Liability</strong><br /> YOUR PARTCIPATION IN AN ENGAGEMENT IS VOLUNTARY, AND YOU FULLY ACCEPT ANY AND ALL ASSOCIATED RISK WITH SUCH PARTICIPATION. YOU FURTHER RELEASE (BOTH ON BEHALF OF YOU AND YOUR FAMILY, FRIENDS, HEIRS, EXECUTORS, AND PERSONAL REPRESENTATIVES), NEON COAT, ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, CONTRACTORS, AGENTS AND REPRESENTATIVES, AND THEIR RESPECTIVE SUCCESSORS, HEIRS AND ASSIGNS (COLLECTIVELY, THE &ldquo;RELEASEES&rdquo;), FROM AND AGAINST ANY LOSS (WHETHER OF WAGES OR OTHER EARNINGS, CONSORTIUM OR OTHERWISE), INJURY, DISABILITY, DEATH, COST, EXPENSE, DAMAGE (WHETHER DIRECT, INDIRECT, GENERAL, SPECIAL, CONSEQUENTIAL AND/OR OTHERWISE) OR OTHER CLAIM OF ANY KIND WHATSOEVER (COLLECTIVELY, &ldquo;CLAIMS&rdquo;) DIRECTLY OR INDIRECTLY ARISING OR RESULTING FROM, OR OTHERWISE RELATING TO, YOUR PARTICIPATION IN AN ENGAGEMENT. YOU ACKNOWLEDGE THAT NEON COAT DOES NOT OWN, ENDORSE OR CONTROL ANY ENGAGEMENT POSTING OR OFFER AND ALL SUCH POSTINGS AND OFFERS ARE ENDORSED BY THE APPLICABLE ORGANIZER.</p>
              <p><strong>Disputes between You and an Organizer</strong><br /> Your use of the Neon Coat Services is at your own risk. Neon Coat is not responsible or liable for the conduct of, or your interactions with, any Organizers (or their employees, contractors, or other Neon Coat models) or for any related damage or harm. Any dispute that you may have regarding the conduct of any Organizer or the quality or characteristics of an Engagement is between you and the applicable Organizer. Neon Coat makes no representations or warranties of any kind in connection with Organizers or Engagements.</p>
              <p><strong>DMCA</strong><br /> In accordance with the Digital Millennium Copyright Act (DMCA) and other applicable law, Neon Coat will terminate, in appropriate circumstances and at Neon Coat&rsquo;s sole discretion, subscribers or account holders who are deemed to be repeat infringers. Neon Coat may also at its sole discretion limit access to the Neon Coat Services or terminate the accounts of any users who infringe any intellectual property rights of others, regardless of whether there is any repeat infringement.</p>
              <p><strong>Neon Coat&rsquo;s Rights</strong><br /> As between you and Neon Coat, all information, materials and content of the Neon Coat Services, including but not limited to text, graphics, data, formatting, graphs, designs, HTML, look and feel, photographs, music, sounds, images, software, videos, designs, typefaces, source and object code, format, queries, and algorithms, is owned by Neon Coat or is used by Neon Coat with permission. Subject to the terms of our Privacy Policy, when you create, share, link to, or otherwise make available any information, including without limitation your name, modeling agency, contact information, social media profiles, uploaded pictures and images (including selfies), ratings or other feedback about an Engagement or Organizer, or other content (&ldquo;<strong>Model Content</strong>&rdquo;), you grant us a nonexclusive, royalty-free, perpetual, irrevocable and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display such Model Content throughout the world in any manner or media, on or off the Neon Coat Services. Neon Coat reserves all rights not expressly set forth in these Terms. You hereby irrevocably waive in favor of Neon Coat any and all moral rights that you may possess in or to any requests, questions or responses that you submit or post on or through the Neon Coat Services.</p>
              <p><strong>Feedback</strong><br /> Any suggestions, comments or other feedback you give us about the Neon Coat Services (&ldquo;<strong>Feedback</strong>&rdquo;) will constitute our confidential information. You acknowledge and agree that we are free to use, disclose, reproduce, license, distribute and exploit Feedback in our sole discretion, without any obligation or compensation to you.</p>
              <p><strong>Disclaimers</strong><br /> EXCEPT AS REQUIRED OTHERWISE OF NEON COAT BY APPLICABLE LAW, THE NEON COAT SERVICES AND ANY OTHER SERVICE AND CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE SERVICES (INCLUDING ANY MODEL CONTENT) ARE PROVIDED TO YOU ON AN &ldquo;AS IS,&rdquo; &ldquo;AS AVAILABLE&rdquo; BASIS WITHOUT ANY REPRESENTATIONS OR WARRANTIES OF ANY KIND. TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, WE DISCLAIM AND EXCLUDE ANY AND ALL OTHER WARRANTIES, CONDITIONS, AND REPRESENTATIONS (EXPRESS OR IMPLIED, ORAL OR WRITTEN), AND ALL OTHER TERMS THAT MAY BE IMPLIED INTO THESE TERMS BY LAW, WITH RESPECT TO THE NEON COAT SERVICES AND CONTENT INCLUDED ON OR OTHERWISE MADE AVAILABLE TO YOU THROUGH THE NEON COAT SERVICES (INCLUDING ANY MODEL CONTENT).</p>
              <p><strong>Limitation of Liability</strong><br /> TO THE EXTENT NOT PROHIBITED BY LAW, IN NO EVENT WILL NEON COAT BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY DIRECT, INCIDENTAL, SPECIAL, INDIRECT, CONSEQUENTIAL, EXEMPLARY, OR PUNITIVE DAMAGES WHATSOEVER INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROPERTY, PHYSICAL LOSSES, DEATH OR ANY OTHER DAMAGES OR LOSSES, ARISING OUT OF OR RELATED TO THESE TERMS, THE NEON COAT SERVICES (INCLUDING THE APP AND ANY MODEL CONTENT), ENGAGEMENTS, AN ORGANIZER&rsquo;S CONDUCT, AND/OR THIRD PARTY SERVICES OR MATERIALS, HOWEVER CAUSED, REGARDLESS OF THE THEORY OF LIABILITY (CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY) AND EVEN IF NEON COAT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. (BECAUSE SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL OR CONSEQUENTIAL DAMAGES, THIS LIMITATION MAY NOT APPLY TO YOU.)</p>
              <p>FOR ANY LOSS OR DAMAGE THAT IS NOT EXCLUDED UNDER THESE TERMS, THE TOTAL LIABILITY OF NEON COAT AND ITS DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES AND INVESTORS TO YOU WILL NOT EXCEED FIFTY DOLLARS (USD $50.00).</p>
              <p><strong>Waiver</strong><br /> Except as expressly provided in these Terms, Neon Coat and the Organizers do not intend to accept liability with respect to your use of the Neon Coat Services. AS SUCH, YOU WAIVE ANY AND ALL RIGHTS YOU HAVE TO SUE OR MAKE CLAIMS AGAINST NEON COAT AND ITS DIRECTORS, OFFICERS, OWNERS, MANAGERS, AGENTS, OR EMPLOYEES (THE &ldquo;NEON COAT PARTIES&rdquo;) AND THE ORGANIZER(S) FOR ANY DAMAGES OR LOSSES ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE NEON COAT SERVICES INCLUDING, WITHOUT LIMITATION, ANY ACTIONS OR INACTION OF THE ORGANIZER(S). YOUR WAIVER DOES NOT EXTEND TO ANY DAMAGES OR LOSSES RESULTING FROM ANY PARTY&rsquo;S GROSS NEGLIGENCE OR WILLFUL MISCONDUCT.</p>
              <p>IF YOU ARE A CALIFORNIA RESIDENT, YOU WAIVE YOUR RIGHTS UNDER CALIFORNIA CIVIL CODE &sect;1542, WHICH STATES: &ldquo;A GENERAL RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.&rdquo;</p>
              <p><strong>Indemnification</strong><br /> YOU AGREE TO INDEMNIFY, DEFEND AND HOLD HARMLESS THE RELEASEES FROM AND AGAINST ANY AND ALL DEMANDS, SUITS, PROCEEDINGS, ACTIONS, JUDGMENTS AND OTHER CLAIMS OF ANY KIND WHATSOEVER THAT ANY OF THEM MAY SUFFER OR INCUR, DIRECTLY OR INDIRECTLY, ARISING OR RESULTING FROM, OR OTHERWISE RELATING TO, YOUR USE OF THE NEON COAT SERVICES (INCLUDING ANY USE OF YOUR NEON COAT ACCOUNT, WHETHER OR NOT AUTHORIZED BY YOU), PARTICIPATION IN AN ENGAGEMENT, AND ANY RESULTING OR RELATED ACTS, EVENTS OR CIRCUMSTANCES.</p>
              <p><strong>Third Party Software and Links</strong><br /> The App and the Site may contain links to third party websites, advertisers, services, special offers, or other events or activities that are not owned or controlled by us. Neon Coat does not endorse any such sites or the information, materials, products or services contained on or accessible through such sites. Neon Coat has no control over, and assumes no responsibility for, the content, privacy policies or practices of any third party websites or services. If you access a third-party website from the App or the Site, you do so at your own risk, and you understand that these Terms do not apply to your use of such sites. You expressly relieve Neon Coat from any and all liability arising from your use of any third-party website or services or third party-owned content. Additionally, your dealings with or participation in promotions of advertisers found on the App or the Site, including payment and delivery of goods, and any other terms (such as warranties) are solely between you and such advertisers. You agree that Neon Coat will not be responsible for any loss or damage of any sort relating to your dealings with such advertisers. Neon Coat encourages you to be aware of when you leave the App or the Site, and to read the terms and conditions and privacy policy of any third-party website or service that you visit.</p>
              <p><strong>Changes to the Services</strong><br /> Neon Coat reserves the right in our discretion to review, improve, change or discontinue, temporarily or permanently, the Neon Coat Services and/or any features, information, materials or content on the Neon Coat Services with or without providing notice to you. Neon Coat will not be liable to you or any third party for any changes or discontinuance of the Neon Coat Services or any part of the Neon Coat Services.</p>
              <p><strong>Consent to Electronic Communications</strong><br /> By using the Neon Coat Services, you agree that we may communicate with you electronically regarding your use of the Neon Coat Services and that any notices, agreements, disclosures or other communications that we send to you electronically will satisfy any legal communication requirements, including that the communications be in writing. To withdraw your consent from receiving electronic notice, please notify us at <a href="mailto:info@neoncoat.com">info@neoncoat.com</a>.</p>
              <p><strong>Suspension and Termination</strong><br /> Neon Coat may suspend or terminate your rights to access or use the Neon Coat Services (including the App) for any reason at all and with or without notice at Neon Coat&rsquo;s sole discretion. Suspension or termination may include restricting access to and use of the App. All of the terms of these Terms (excluding the license grant) will survive any termination or suspension. Termination of access to the Neon Coat Services will not release either party from any obligations incurred prior to such termination, and Neon Coat may retain and continue to use for its own internal purposes any information previously provided by you.</p>
              <p><strong>Governing Law; Arbitration</strong><br /> PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY. THEY REQUIRE YOU TO ARBITRATE DISPUTES WITH NEON COAT, AND LIMIT THE MANNER IN WHICH YOU CAN SEEK RELIEF FROM NEON COAT.</p>
              <p>These Terms will be governed by and construed in accordance with the laws of the State of New York and the United States of America, without resort to any conflict of law provisions. In the event of any controversy or claim arising out of or relating in any way to these Terms or the Neon Coat Services, you and Neon Coat agree to consult and negotiate with each other and, recognizing your mutual interests, try to reach a solution satisfactory to both parties. If we do not reach settlement within a period of 60 days, then either of us may, by notice to the other demand mediation under the mediation rules of the JAMS/Endispute (&ldquo;<strong>JAMS</strong>&rdquo;) in New York, New York. Both you and Neon Coat give up the right to litigate disputes and may not proceed to arbitration without first trying mediation, but you and Neon Coat are not required to arbitrate any dispute in which either party seeks equitable and other relief from the alleged unlawful use of copyrights, trademarks, trade names, logos, trade secrets or patents. Whether the dispute is heard in arbitration or in court, you and Neon Coat will not commence against the other a class action, class arbitration or other representative action or proceeding.</p>
              <p>If settlement is not reached within 60 days after service of a written demand for mediation, any unresolved controversy or claim will be resolved by arbitration in accordance with the rules of JAMS before a single arbitrator in New York, New York. The language of all proceedings and filings will be English. The arbitrator will render a written opinion including findings of fact and law and the award and/or determination of the arbitrator will be binding on the parties, and their respective administrators and assigns, and will not be subject to appeal. Judgment may be entered upon the award of the arbitrator in any court of competent jurisdiction. The expenses of the arbitration will be shared equally by the parties unless the arbitration determines that the expenses will be otherwise assessed and the prevailing party may be awarded its attorneys&rsquo; fees and expenses by the arbitrator. It is the intent of the parties that, barring extraordinary circumstances, arbitration proceedings will be concluded within 90 days from the date the arbitrator is appointed. The arbitrator may extend this time limit only if failure to do so would unduly prejudice the rights of the parties. Failure to adhere to this time limit will not constitute a basis for challenging the award. Consistent with the expedited nature of arbitration, pre-hearing information exchange will be limited to the reasonable production of relevant, non-privileged documents, carried out expeditiously.</p>
              <p>For any disputes that are not handled by arbitration, you agree that any action at law or in equity arising out of or relating to these Terms will be filed only in the state and federal courts located in New York, New York and you hereby irrevocably and unconditionally consent and submit to the exclusive jurisdiction of such courts over any suit, action or proceeding arising out of these Terms or the use of the Neon Coat Services.</p>
              <p><strong>No Circumvention</strong><br /> Any attempt to try to circumvent Neon Coat (e.g., by dealing with Organizers directly for the express purpose of circumventing the Neon Coat Services) is prohibited and may result in the termination of your account and/or access to the Neon Coat Services.</p>
              <p><strong>General</strong><br /> Enforcement of these Terms is solely at Neon Coat&rsquo;s discretion. Failure to enforce any part of these Terms in some instances does not constitute a waiver of our right to enforce the same or other part of these Terms in other instances. If any provision of these Terms is or becomes unlawful, void or otherwise unenforceable (including the warranty disclaimers and liability limitations above), then that provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision, and the remaining provisions of these Terms will continue in full force and effect. The section headings of these Terms are for reference purposes only and will not affect the meaning or interpretation of these Terms.</p>
              <p>These Terms (and the other policies and terms referred to above) make up the entire agreement between you and us regarding the Neon Coat Services, and they supersede any prior agreements that may have been made.</p>
              <p><strong>Contact Us</strong><br /> If you have any questions about these Terms, please email <a href="mailto:info@neoncoat.com">info@neoncoat.com</a>.</p>
            </div>
          </Terms>
        </div>
        <div className="col" />
      </div>
    </Layout>
  )
}

export default TermsModels
